import Vue from 'vue';
import { createObserver } from '@/helpers';
/*

In the card that is not in the compare mode, watch the `details` computed. When it changes, set the currently active source object in the service state.

In the card that is in the compare mode, add a listener for changes.

*/

const initialState = {
  source: null,
  parties: [],
};
let originalCardTableContainerRef = null;
let compareCardTableContainerRef = null;

export const setCardTableContainerRef = (el, type) => {
  if (type === 'original') {
    originalCardTableContainerRef = el;
  } else if (type === 'compare') {
    compareCardTableContainerRef = el;
  }
};

export const scrollTableContainer = (position, type) => {
  let el =
    type === 'original'
      ? compareCardTableContainerRef
      : originalCardTableContainerRef;

  el?.scrollTo({
    top: position.top,
    left: position.left,
    behavior: 'smooth',
  });
};

export const sourceState = Vue.observable(initialState);

export const setSourceDetails = (details) => {
  sourceState.source = details;

  sourceState.parties = details?.items?.map(({ party }) => party) || [];
};
